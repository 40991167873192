import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const WBForm = React.lazy(() => import("../components/WBForm"));
const WBFormResult = React.lazy(() => import("../components/WBFormResult"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarOne = React.lazy(() => import("../components/NavbarTwo"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const Track = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarOne />

          {/* Breadcrumb */}
          <Breadcrumb title={"Track our Shipment"} />

          {/* WBForm */}
          <div className='container'>
        <div className='contact-area  '>
          <div className='row g-0 justify-content-center'>
            <div className='col-lg-12'>
          <WBForm />
          </div>
          </div>
        </div>
      </div>
          {/* WBFormResult */}
          <WBFormResult   />

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>{" "}
      </Fragment>
    </>
  );
};

export default Track;
