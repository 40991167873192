import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const BannerThree = React.lazy(() => import("../components/BannerThree"));
const CompanyOne = React.lazy(() => import("../components/CompanyOne"));
const FeatureTwo = React.lazy(() => import("../components/FeatureTwo"));
const FooterThree = React.lazy(() => import("../components/FooterThree"));
const LogisticsOne = React.lazy(() => import("../components/LogisticsOne"));
const SPSProfile = React.lazy(() => import("../components/SPSProfile"));
const NavbarThree = React.lazy(() => import("../components/NavbarTwo"));
const ServiceThree = React.lazy(() => import("../components/ServiceThree"));
const ShipmentOne = React.lazy(() => import("../components/ShipmentOne"));
const SolutionOne = React.lazy(() => import("../components/SolutionOne"));
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const TestimonialThree = React.lazy(() =>
  import("../components/TestimonialThree")
);

const HomeSix = () => {
  
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>          

          {/* Navbar Three */}
          <NavbarThree />


          {/* Banner Three */}
          <BannerThree  />

		{/* SPSProfile One */}
          <SPSProfile />
          {/* Service Three */}
          <ServiceThree />
          
		{/* Feature Two */}
          <FeatureTwo />

          {/* Solution One */}
          <SolutionOne />

          

          {/* Logistics One */}
          <LogisticsOne />

          {/* Shipment One */}
          <ShipmentOne />

          {/* Testimonial Three */}
          <TestimonialThree />

          {/* Company One */}
          <CompanyOne />

          {/* Footer Three */}
          <FooterThree />
        </Suspense>
      </Fragment>
    </>
  );
};

export default HomeSix;
